<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="card card-custom gutter-b example example-compact"
          v-if="filter"
        >
          <div class="card-header">
            <div class="row my-2 align-items-center">
              <div class="col-md-2">
                <div class="input-icon">
                  <input
                    type="text"
                    v-model="id"
                    v-debounce:400ms="myFn"
                    class="form-control"
                    placeholder="Id"
                    id="kt_datatable_search_query"
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-icon">
                  <input
                    type="text"
                    v-model="name"
                    v-debounce:400ms="myFn"
                    class="form-control"
                    placeholder="Mijoz nomi"
                    id="kt_datatable_search_query"
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>
              <div class="col-md-2">
                <div class="input-icon">
                  <input
                    type="text"
                    v-model="inn"
                    v-debounce:400ms="myFn"
                    class="form-control"
                    placeholder="inn"
                    id="kt_datatable_search_query"
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>
              <div class="col-md-2">
                <div class="d-flex align-items-center">
                  <!-- <label class="mr-3 mb-0 d-none d-md-block">Status:</label> -->
                  <select
                    v-model="type"
                    @change="myFn"
                    class="form-control"
                    id="kt_datatable_search_status"
                  >
                    <option value="" selected disabled>Shakli</option>
                    <option value="NAQD">Kelishuv</option>
                    <option value="CONT">Shartnoma</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="d-flex align-items-center">
                  <!-- <label class="mr-3 mb-0 d-none d-md-block">Type:</label> -->
                  <select
                    @change="myFn"
                    v-model="conttype"
                    class="form-control"
                    id="kt_datatable_search_type"
                  >
                    <option value="" selected disabled>Turi</option>
                    <option value="MU">Muddatli</option>
                    <option value="MS">Muddati cheklanmagan</option>
                    <option value="SU">Summaga bog'liq</option>
                  </select>
                </div>
              </div>
              <div class="col">
                <a @click="reset" class="btn btn-primary font-weight-bolder">
                  {{ $t('BREADCRUMBS.CLEAN') }}</a
                >
              </div>
            </div>

            <!-- <div class="col-lg-2 col-xl-4 mt-5 mt-lg-0 text-right">
                <v-btn small @click="reset" color="error">Tozalash</v-btn>
              </div> -->
          </div>
        </div>
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">{{ $t('BREADCRUMBS.CONTRACTS') }}</h3>
            </div>

            <div class="card-button d-flex">
              <div class="card-toolbar mr-1">
                <a
                  @click="filter = !filter"
                  class="btn btn-warning font-weight-bolder"
                >
                  <span class="svg-icon svg-icon-white"
                    ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/General/Search.svg--><svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="25px"
                      height="25px"
                      viewBox="0 0 25 25"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="25" height="25" />
                        <path
                          d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                        />
                        <path
                          d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                        />
                      </g></svg
                    ><!--end::Svg Icon--></span
                  >

                  {{ $t('BREADCRUMBS.SEARCH') }}</a
                >
              </div>

              <div class="card-toolbar">
                <router-link
                  to="/contractreg"
                  class="btn btn-primary font-weight-bolder"
                >
                  <span class="svg-icon svg-icon-white"
                    ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Files/File.svg--><svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                        />
                        <rect
                          fill="#000000"
                          x="6"
                          y="11"
                          width="9"
                          height="2"
                          rx="1"
                        />
                        <rect
                          fill="#000000"
                          x="6"
                          y="15"
                          width="5"
                          height="2"
                          rx="1"
                        />
                      </g></svg
                    ><!--end::Svg Icon--></span
                  >

                  {{ $t('BREADCRUMBS.CONTRACT') }} +</router-link
                >
              </div>
            </div>
          </div>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import ContractsAction from "@/view/content/dropdown/ContractsAction.vue";

export default {
  data() {
    return {
      overlay: true,
      filter: false,
      conttype: '',
      type: '',
      inn: '',
      name: '',
      id: '',
      payTypes: [
        { name: 'Muddatli', value: 'MU' },
        { name: 'Muddati cheklanmagan', value: 'MS' },
        { name: "Summaga bog'liq", value: 'SU' }
      ],
      types: [
        { name: 'Kelishuv', value: 'NAQD' },
        { name: 'Shartnoma', value: 'CONT' }
      ]
    }
  },
  props: {
    items: Object
  },
  created() {
    if (this.$store.state.requests.filterData.data !== undefined) {
      const data = this.$store.state.requests.filterData.data
      if (
        this.$store.state.requests.filterData.path.substring(
          0,
          this.$route.path.lastIndexOf('/')
        ) == this.$route.path.substring(0, this.$route.path.lastIndexOf('/'))
      ) {
        this.filter = true
        this.conttype = data.contract_type
        this.type = data.payment_type
        this.inn = data.client__inn__icontains
        this.name = data.client__full_name__icontains
        this.id = data.id
      } else {
        this.filter = false
        this.$store.commit('setFilterData', {
          data: undefined,
          path: ''
        })
      }
    }
  },
  methods: {
    reset() {
      this.id = ''
      this.name = ''
      this.inn = ''
      this.type = ''
      this.conttype = ''
      this.$store.commit('setFilterData', {
        data: undefined,
        path: ''
      })
      this.myFn()
    },
    myFn() {
      const data = {}
      if (this.id !== '') {
        data.id = this.id
      }
      if (this.name !== '') {
        data.client__full_name__icontains = this.name
      }
      if (this.inn !== '') {
        data.client__inn__icontains = this.inn
      }
      if (this.conttype !== '') {
        data.contract_type = this.conttype
      }
      if (this.type !== '') {
        data.payment_type = this.type
      }
      this.$store.commit('setFilterData', {
        data: data,
        path: this.$route.path
      })
      this.$store.dispatch('contractsSearch', data)
      if (this.$route.path !== '/allcontracts/1') {
        this.$router.push('/allcontracts/' + '1')
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('BREADCRUMBS.SOLD') },
      { title: this.$t('BREADCRUMBS.CONTRACTS') }
    ])
    this.overlay = false
  }
}
</script>

<style scoped>
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
  min-height: 300px;
}
</style>
